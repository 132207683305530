"use client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useSWR, { KeyedMutator, SWRConfig, SWRConfiguration, State, useSWRConfig } from "swr";
import { crudFetcherCommon, crudFetcherNextApi, crudFetcherSeller } from "services/crud";
import useContentLanguage from "@/hooks/useContentLanguage";
import { hashObject } from "@/utils/data";
import crudFetcher from "./crudFetcher";
import useClientContext from '@/context/ClientContext';

const devRevalidationOptions = {
  revalidateOnMount: true,
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshInterval: 2 * 60 * 1000, // 2 min
};

const prodRevalidationOptions = {
  revalidateOnMount: true,
  revalidateIfStale: true,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
  refreshInterval: 10 * 60 * 1000, // 10 min
};

const revalidationOptions = process.env.NODE_ENV === "development" ? devRevalidationOptions : prodRevalidationOptions;

export interface CustomCache<Data = any> {
  _cache: Map<any, any>;
  keys(): IterableIterator<string>;
  get(key: string): State<Data> | undefined;
  set(key: string, value: State<Data>): void;
  delete(key: string): void;
}
interface CustomSWRConfig {
  children: JSX.Element;
  locale?: string;
}

export const CustomSWRConfig = ({ children }: CustomSWRConfig) => {
  const { locale } = useClientContext();
  const fetcher = useCallback(
    (args: any) => {
      const [resource, options] = args;
      const { crudFetcher, contentLanguage, stop, defaultData = null, withPagination, ...rest } = options || {};

      if (stop) {
        return new Promise((resolve) => resolve(defaultData));
      }

      let headers = {} as any;
      if (contentLanguage || locale) {
        headers["Accept-Language"] = contentLanguage || locale;
      }

      return crudFetcher
        ? crudFetcher.get({
            url: resource,
            options: { headers },
            withPagination,
          })
        : fetch(resource, { ...rest }).then((res) => res.json());
    },
    [locale]
  );

  return (
    <SWRConfig
      value={{
        fetcher,
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
          // Never retry on 404.
          if (error.status === 404) return;

          // Never retry for a specific key.
          if (key === "/api/user") return;

          // Only retry up to 10 times.
          if (retryCount >= 10) return;

          // Retry after 5 seconds.
          setTimeout(() => revalidate({ retryCount }), 5000);
        },
        onError: (error, key) => {
          if (error.status !== 403 && error.status !== 404) {
            // We can send the error to Sentry,
            // or show a notification UI.
          }
        },
        ...revalidationOptions,
      }}
    >
      {children}
    </SWRConfig>
  );
};

const addContentParam = (url: string, valueLang?: string) => {
  if (!valueLang) {
    return url;
  }

  if (url.indexOf("?") !== -1) {
    return `${url}&content-language=${valueLang}`;
  } else {
    return `${url}?content-language=${valueLang}`;
  }
};

interface UseCommonSWR {
  key: any;
  options?: SWRConfiguration;
  defaultData?: any;
  isSensitiveContentLanguage?: boolean;
  normalizeData?: (data: any) => any;
  stop?: boolean;
  withPagination?: boolean;
  accumulate?: boolean;
  cleanDirtyData?: boolean;
}

export interface DataSWR<T> {
  data: T;
  error: any;
  mutate: KeyedMutator<T>;
  isValidating: boolean;
  isLoading: boolean;
  onNextPage?: () => void;
  ended?: boolean;
}

export interface PaginationData<T> {
  count: number;
  limit: number;
  nextPage: string | "None";
  prevPage: string | "None";
  result: T;
}

export const useNextApiSWR = <T extends unknown>({ key, options, defaultData, normalizeData, stop }: UseCommonSWR) => {
  const { data, error, mutate, isValidating } = useSWR<T>(
    [key, { crudFetcher: crudFetcherNextApi, stop, defaultData }],
    options || {}
  );

  return useMemo(
    () => ({
      data: normalizeData ? normalizeData(data || defaultData) : data || defaultData,
      error,
      mutate,
      isValidating,
      isLoading: !data && !error && !stop,
    }),
    [data, defaultData, error, isValidating, mutate, normalizeData, stop]
  ) as DataSWR<T>;
};

export const useAssetsSWR = <T extends unknown>({ key, options, defaultData, normalizeData, stop }: UseCommonSWR) => {
  const { data, error, mutate, isValidating } = useSWR<T>([key, { stop, defaultData }], options || {});

  return useMemo(
    () => ({
      data: normalizeData ? normalizeData(data || defaultData) : data || defaultData,
      error,
      mutate,
      isValidating,
      isLoading: !data && !error && !stop,
    }),
    [data, defaultData, error, isValidating, mutate, normalizeData, stop]
  ) as DataSWR<T>;
};

interface useSWRAccumulateLogic extends UseCommonSWR {
  crudFetcher: crudFetcher;
}

const useSWRAccumulateLogic = <T extends unknown>({
  crudFetcher,
  key,
  options,
  defaultData,
  isSensitiveContentLanguage,
  normalizeData,
  stop,
  withPagination,
  accumulate,
  cleanDirtyData = true,
}: useSWRAccumulateLogic) => {
  const { mutate: m } = useSWRConfig();
  const value = useContentLanguage();

  const [nextKey, setNextKey] = useState<string | null>(null);

  useEffect(() => {
    const newKey = isSensitiveContentLanguage ? `${addContentParam(key, value)}` : key;
    setNextKey(newKey);
  }, [isSensitiveContentLanguage, key, value]);

  const keyContent = accumulate ? nextKey : isSensitiveContentLanguage ? `${addContentParam(key, value)}` : key;

  const ops = useMemo(
    () => ({
      crudFetcher,
      contentLanguage: isSensitiveContentLanguage ? value : undefined,
      stop: stop || !keyContent,
      defaultData,
      withPagination,
    }),
    [crudFetcher, defaultData, isSensitiveContentLanguage, keyContent, stop, value, withPagination]
  );

  const { data, error, mutate, isValidating, isLoading } = useSWR([keyContent, ops], options || {});

  const [dirtyData, setDirtyData] = useState<string | null>(null);
  useEffect(() => {
    if (cleanDirtyData) {
      return;
    }
    if (!isLoading && data) {
      setDirtyData(data);
    }

  }, [cleanDirtyData, data, isLoading]);

  const [keyUpdate, setKeyUpdate] = useState(0);
  const fastState = useRef<
    { basicContent: String; key: string; data: PaginationData<T>; mutate: KeyedMutator<T>; dirty?: boolean }[]
  >([]);

  useEffect(() => {
    if (accumulate && keyContent && !isLoading) {
      const idx = fastState.current.findIndex((i) => i.key === keyContent);
      if (idx === -1) {
        const newList = [...fastState.current, { basicContent: key, key: keyContent, data, mutate }];
        fastState.current = newList;
      } else if (hashObject(fastState.current[idx].data) !== hashObject(data)) {
        const newList = [...fastState.current];
        newList[idx].data = data;
        newList[idx].dirty = false;
        fastState.current = newList;
      }
      setKeyUpdate(Date.now());
    }
  }, [accumulate, keyContent, isLoading, key, data, mutate]);

  const ended = useMemo(() => {
    const find = fastState.current
      .filter((i) => i.basicContent === key)
      .reverse()
      .find((i) => i.data);
    if (find && find.data.nextPage && find.data.nextPage !== "None") {
      return false;
    }
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, keyUpdate]);

  const onNextPage = useCallback(() => {
    const find = fastState.current
      .filter((i) => i.basicContent === key)
      .reverse()
      .find((i) => i.data);
    if (find && find.data.nextPage && find.data.nextPage !== "None") {
      setNextKey(find.data.nextPage);
    } else {
      setNextKey(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, keyUpdate]);

  const preparedData = useMemo(() => {
    if (accumulate) {
      let arr: any = [];
      fastState.current
        .filter((i) => i.basicContent === key)
        .forEach((item) => {
          arr = [
            ...arr,
            ...(normalizeData ? normalizeData(item.data?.result || defaultData || []) : item.data?.result || defaultData || []),
          ];
        });
      return arr;
    } else {
      return normalizeData
        ? normalizeData(dirtyData || data || defaultData)
        : dirtyData || data || defaultData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accumulate, dirtyData, data, defaultData, key, normalizeData, keyUpdate]);

  const mutateList = useCallback(async () => {
    let idx = 0;
    for await (const item of fastState.current) {
      async function g() {
        const { crudFetcher, ...args } = { ...ops, stop: false, url: item.key };
        return await ops.crudFetcher.get(args);
      }

      const r = await m((k: [url: string]) => k?.[0] === item.key, await g, { revalidate: true });
      fastState.current[idx].data = r[0];
      idx++;
    }

    setKeyUpdate(Date.now());
  }, [m, ops]);

  return useMemo(
    () => ({
      data: preparedData,
      error,
      mutate: (data) => {
        const dirtyList = fastState.current.map((i) => ({ ...i, dirty: true }));
        fastState.current = dirtyList;

        accumulate ? mutateList() : data ? mutate(mutateData(preparedData, data), { revalidate: false }) : mutate();
      },
      isValidating,
      isLoading: !data && !error && !stop,
      onNextPage,
      ended: ended,
    }),
    [accumulate, data, ended, error, isValidating, mutate, mutateList, onNextPage, preparedData, stop]
  ) as DataSWR<T>;
};

function mutateData(list?: any, data?: any) {
  if (Array.isArray(list) && Array.isArray(data)) {
    if (typeof list[0] !== "object" ) {
      return data;
    }

    data.forEach(d => {

      const idx = list.findIndex(i => i.id === d.id);
      if (idx !== -1) {
        // Update if exist
        list[idx] = {...list[idx], ...d}
      } else {
        // otherwise add
        list.push(d);
      }
    });
  }

  return list;
}

export const useCommonSWR = <T extends unknown>(options: UseCommonSWR) => {
  return useSWRAccumulateLogic({ crudFetcher: crudFetcherCommon, ...options }) as DataSWR<T>;
};

export const useSellerSWR = <T extends unknown>(options: UseCommonSWR) => {
  return useSWRAccumulateLogic({ crudFetcher: crudFetcherSeller, ...options }) as DataSWR<T>;
};
