"use client";
import { useEffect, useMemo, useState } from "react";
import { DevLanguage } from "@/constants/common";
import { DataSWR, useAssetsSWR, useCommonSWR, useNextApiSWR } from "@/libs/swr";
import { IApiBanner } from "@/types/interfaces/banners";
import {
  IApiBrand,
  IApiCountry,
  IApiCurrency,
  IApiDependency,
  IApiLanguage,
  IApiMessenger,
  IApiProvincesList,
} from "@/types/interfaces/primitives";
import { IApiCategoryMapped } from "@/types/interfaces/aggregated";
import { IApiBreadcrumb, IApiCategoryOfList } from "@/types/interfaces/categories";
import { IApiProductDetails, IApiProductOfList } from "@/types/interfaces/products";
import { IApiStore, IApiStoreOfList, IApiStoresTagsList } from "@/types/interfaces/stores";
import useClientContext from "@/context/ClientContext";
import apiPublic, { nextApiPublic } from "../constants/apiPublic";
import { IApiPaginatedResult, IApiProductReview, IApiProductReviewAverageValues } from "@/types/interfaces/reviews";
import { IApiPublicProductVariant } from "@/types/interfaces/variants";
import { IApiCustomerPropertyOfList, IApiCustomerProductVariantProperty } from "@/types/interfaces/properties";
import { prepareQueryParamsToString } from "@/utils/url";
import { sortByOrdering, sortCategories } from "@/utils/sort";
import { ICategoryFilters } from "../pages/api/category-filters.api";
import { defaultCurrency } from "@/hooks/useCurrency";
import publicRequests from "../requests";
import {IApiStaticContent} from '@/types/interfaces/static-content';
import {IApiOrderPaymentType, IApiPaymentStatus, IApiPaymentType} from '@/types/interfaces/finances';

export const useDataPublicLanguages = (): DataSWR<IApiLanguage[]> => {
  const [data, setData] = useState<IApiLanguage[] | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!loading && !data) {
      setLoading(true);
      publicRequests
        .getLanguages()
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          setData([]);
          setLoading(false);
        });
    }
  }, [data, loading]);

  function normalizeData(d: IApiLanguage[]) {
    return d.map((item: any) => ({
      ...item,
      value: `${item.code}`.toLowerCase(),
    }));
  }

  const preparedData = useMemo(() => {
    if (global.__env?.DEV_LANGUAGE) {
      return [DevLanguage, ...normalizeData(data || [])];
    }
    return normalizeData(data || []);
  }, [data]);

  return {
    data: preparedData,
    error: false,
    isLoading: loading || !data,
    isValidating: false,
    mutate: (args: any): any => {},
  };
  // TODO crashing
  // return useCommonSWR<IApiLanguage[]>({
  //   key: apiPublic.languages(),
  //   defaultData: [],
  //   normalizeData(data) {
  //     return data.map((item: any) => ({
  //       ...item,
  //       value: `${item.code}`.toLowerCase(),
  //     }));
  //   },
  // });
};

export const useDataPublicDependencies = () => {
  return useAssetsSWR<IApiDependency[]>({
    key: `/assets/countries/dependencies.json`,
    defaultData: [],
  });
};

export const useDataPublicCurrencies = () => {
  return useCommonSWR<IApiCurrency[]>({
    key: apiPublic.currencies(),
    // mock for MVP v.1
    defaultData: [defaultCurrency],
    stop: true,
  });
};

export const useDataPublicStores = () => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiStoreOfList[]>({
    key: apiPublic.stores(locale),
    defaultData: [],
  });
};

export const useDataPublicStore = (slug: any) => {
  return useCommonSWR<IApiStore | null>({
    key: apiPublic.store(slug),
  });
};

export const useDataPublicStoreTags = () => {
  return useCommonSWR<any>({
    key: apiPublic.storesTags(),
    defaultData: [],
    isSensitiveContentLanguage: true,
  });
};

export const useDataPublicStoreTag = (storeId: any) => {
  return useCommonSWR<any>({
    key: apiPublic.storeTag(storeId),
    defaultData: [],
  });
};

export const useDataPublicSocialNetworks = () => {
  return useCommonSWR<any>({
    key: apiPublic.socialNetworks(),
    defaultData: [],
  });
};

export const useDataPublicBrands = (categoryId?: any, storeId?: any, searchStr?: string) => {
  const queryStr = prepareQueryParamsToString({
    categories: categoryId,
    store: storeId,
    search: searchStr,
  });

  return useCommonSWR<IApiBrand[]>({
    key: apiPublic.brands() + (queryStr ? `?${queryStr}` : ""),
    defaultData: [],
  });
};

export const useDataPublicBrand = (brandId: any) => {
  return useCommonSWR<IApiBrand | null>({
    key: apiPublic.brand(brandId),
    stop: brandId == '-1',
  });
};

export const useDataPublicBrandsByIds = (ids?: number[]) => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiBrand[]>({
    key: apiPublic.brandsByIds(locale, ids),
    defaultData: null,
  });
};

export const useDataPublicProperties = (categoryId?: any, storeId?: any, searchStr?: string) => {
  const { locale } = useClientContext();

  const queryStr = prepareQueryParamsToString({
    store: storeId,
    search: searchStr,
  });

  return useCommonSWR<IApiCustomerPropertyOfList[]>({
    key: categoryId
      ? apiPublic.propertiesOfCategory(locale, categoryId, queryStr)
      : apiPublic.properties(locale, queryStr),
    defaultData: [],
  });
};

export const useDataPublicCategoryFilters = (categoryId?: any, storeId?: any, searchStr?: string, sale?: string, stop?: boolean) => {
  const { locale } = useClientContext();

  const queryStr = prepareQueryParamsToString({
    categories: categoryId,
    store: storeId,
    sale,
    search: searchStr,
  });

  return useNextApiSWR<ICategoryFilters>({
    key: nextApiPublic.categoryFilters(locale) + `&${queryStr}`,
    defaultData: {
      brands: [],
      stores: [],
      categories: [],
      properties: [],
      min_price: null,
      max_price: null,
    },
    stop,
  });
};

export const useDataPublicStoreActiveCategories = (storeId?: any) => {
  const { locale } = useClientContext();

  const queryStr = prepareQueryParamsToString({
    store: storeId,
  });

  return useNextApiSWR<IApiCategoryMapped[]>({
    key: nextApiPublic.storeActiveCategories(locale) + `&${queryStr}`,
    defaultData: [],
    normalizeData: (d) => (d.data || []).sort(sortByOrdering),
  });
};

export const useDataPublicProperty = (propertyId: any) => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiCustomerPropertyOfList | null>({
    key: apiPublic.property(locale, propertyId),
  });
};

export const useDataPublicProduct = (slug: string) => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiProductDetails | null>({
    key: apiPublic.product(locale, slug),
    isSensitiveContentLanguage: true,
  });
};

export const useDataPublicProductVariants = (slug: string) => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiPublicProductVariant[]>({
    key: apiPublic.productVariants(locale, slug),
    isSensitiveContentLanguage: true,
    defaultData: [],
    stop: !slug,
  });
};

export const useDataPublicProductVariant = (productSlug: string, variantId: any) => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiPublicProductVariant | null>({
    key: apiPublic.productVariant(locale, productSlug, variantId),
    isSensitiveContentLanguage: true,
    stop: !productSlug || !variantId,
  });
};

export const useDataPublicProductVariantProperties = (variantId: any) => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiCustomerProductVariantProperty[]>({
    key: apiPublic.productVariantProperties(locale, variantId),
    isSensitiveContentLanguage: true,
    defaultData: [],
    stop: !variantId,
  });
};

export const useDataPublicCountries = () => {
  return useCommonSWR<IApiCountry[]>({
    key: apiPublic.countries(),
    defaultData: [],
  });
};

export const useDataPublicCountryProvinces = (countryCode: string, stop?: boolean) => {
  return useCommonSWR<IApiProvincesList>({
    key: apiPublic.countryProvinces(countryCode),
    defaultData: [],
    stop: !countryCode || stop,
  });
};

export const useDataPublicCountryCities = (countryCode: string, stop?: boolean) => {
  return useCommonSWR<any>({
    key: apiPublic.countryCities(countryCode),
    defaultData: [],
    stop: !countryCode || stop,
  });
};

export const useDataPublicDeliveryOptions = () => {
  return useCommonSWR<any>({
    key: apiPublic.storesDeliveryOptions(),
    defaultData: [],
    isSensitiveContentLanguage: true,
  });
};

export const useDataPublicCategories = (stop?: boolean) => {
  const { locale } = useClientContext();

  return useNextApiSWR<IApiCategoryOfList[]>({
    key: nextApiPublic.categories(locale),
    defaultData: [],
    stop,
    normalizeData: (data) => (data.data || []).sort(sortCategories),
  });
};

export const useDataPublicCategoryBreadcrumbs = (categoryId: number) => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiBreadcrumb[]>({
    key: apiPublic.breadcrumbs(categoryId, locale),
    defaultData: [],
  });
};

export const useDataPublicTopCategories = (stop?: boolean) => {
  const { locale } = useClientContext();

  return useNextApiSWR<IApiCategoryOfList[]>({
    key: nextApiPublic.topCategories(locale),
    defaultData: [],
    stop,
    normalizeData: (data) => (data.data || []).sort(sortCategories),
  });
};

export const useDataPublicCategory = (categorySlug: any) => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiCategoryOfList | null>({
    key: apiPublic.categoryBySlug(categorySlug, locale),
  });
};

export const useDataPublicStoreCategories = (storeSlug: any) => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiCategoryOfList[]>({
    key: apiPublic.storeCategories(storeSlug, locale),
    defaultData: [],
    normalizeData: (data) => data.sort(sortCategories),
  });
};

export const useDataPublicStoreTopCategories = (storeSlug: any) => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiCategoryOfList[]>({
    key: `${apiPublic.storeCategories(storeSlug, locale)}&categories_level=0`,
    defaultData: [],
    normalizeData: (data) => data.sort(sortCategories),
    stop: !storeSlug,
  });
};

export const useDataPublicStoresTags = () => {
  return useCommonSWR<IApiStoresTagsList>({
    key: apiPublic.storesTags(),
    defaultData: [],
  });
};

// TODO MOCK REVIEWS
const reviewUser = {
  name: "User name",
  last_visit: "Yesterday 12:12",
  avatar: "https://cdn.marketplace.syntech.info/media/profile/avatars/msg-1-fc-40.jpeg",
};

const reviewProduct1 = {
  variant: "Morning Mist",
  images: [
    {
      name: "image1",
      file: "https://cdn.marketplace.syntech.info/media/gallery/user_6/thumbnail/0.jpeg",
      thumbnail: "https://cdn.marketplace.syntech.info/media/gallery/user_6/thumbnail/0.jpeg",
    },
    {
      name: "image1",
      file: "https://cdn.marketplace.syntech.info/media/gallery/user_9/thumbnail/aceitunas-popurri.jpg",
      thumbnail: "https://cdn.marketplace.syntech.info/media/gallery/user_9/thumbnail/aceitunas-popurri.jpg",
    },
  ],
};

const reviewProduct2 = {
  variant: "Pine Green",
  images: [
    {
      name: "image1",
      file: "https://cdn.marketplace.syntech.info/media/gallery/user_9/thumbnail/aceitunas-popurri.jpg",
      thumbnail: "https://cdn.marketplace.syntech.info/media/gallery/user_9/thumbnail/aceitunas-popurri.jpg",
    },
    {
      name: "image1",
      file: "https://cdn.marketplace.syntech.info/media/gallery/user_6/thumbnail/0.jpeg",
      thumbnail: "https://cdn.marketplace.syntech.info/media/gallery/user_6/thumbnail/0.jpeg",
    },
  ],
};

const reviewComment11 = {
  id: -11,
  user: reviewUser,
  text: "Качество отличное, доставка всего 2 недели. Застегивается хорошо. Довольна покупкой, рекомендую.",
  count_likes: 1,
  self_liked: false,
};

const reviewComment12 = {
  id: -12,
  user: reviewUser,
  text: "Благодарю за смарт браслет . Ооочень быстрая доставка в Украину. Осталась очень довольна. Рекомендую ",
  count_likes: 4,
  self_liked: true,
};

const productReview1 = {
  id: -1,
  user: reviewUser,
  rating: "4.0",
  text: "Флагманская камера, разработанная совместно с компанией Hasselblad эксклюзивно для OnePlus, предлагает вам ведущие в отрасли инновации",
  product: reviewProduct1,
  comments: [reviewComment11, reviewComment12],
  count_likes: 12,
  self_liked: true,
  self_commented: true,
};

const productReview2 = {
  id: -2,
  user: reviewUser,
  rating: "3.5",
  text: "The advanced primary camera integrates class-leading technology  our most innovative camera features. Designed for beginners",
  product: reviewProduct2,
  comments: [],
  count_likes: 0,
  self_liked: false,
  self_commented: false,
};

export const useDataProductReviews = (productSlug: any, filterStr?: string) => {
  return useCommonSWR<IApiPaginatedResult<IApiProductReview[]>>({
    key: `${apiPublic.productReviews(productSlug)}?${filterStr}`,
    defaultData: [],
    withPagination: true,
  });
};

export const useDataProductReviewsAverageValues = (productSlug: any) => {
  return useCommonSWR<IApiProductReviewAverageValues>({
    key: apiPublic.productReviewsAverageValues(productSlug),
    defaultData: {},
  });
};

export const useDataPublicMessengers = () => {
  return useCommonSWR<IApiMessenger[]>({
    key: apiPublic.messengers(),
    defaultData: [],
  });
};

export const useDataPublicBanners = (stop?: boolean) => {
  return useCommonSWR<IApiBanner[]>({
    key: apiPublic.banners(),
    defaultData: [],
    normalizeData: (data) => (data || []).sort(sortByOrdering),
    stop,
  });
};

export const useDataPublicFeaturedProducts = (stop?: boolean) => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiProductOfList[]>({
    key: apiPublic.featuredProducts(locale, '&sale=1&in_stock=1'),
    defaultData: [],
    stop,
    normalizeData: (data) => data.products || [],
  });
};

export const useDataPublicSalesProducts = (stop?: boolean) => {
  const { locale } = useClientContext();

  return useCommonSWR<IApiProductOfList[]>({
    key: apiPublic.salesProducts(locale),
    defaultData: [],
    stop,
    normalizeData: (data) => data.products || [],
  });
};

export const useDataPublicStaticPages = () => {
  return useCommonSWR<IApiStaticContent[]>({
    key: apiPublic.staticPages(),
    defaultData: [],
  });
};

export const useDataPublicOrderPaymentTypes = () => {
  return useCommonSWR<IApiOrderPaymentType[]>({
    key: apiPublic.orderPaymentTypes(),
    defaultData: [],
  });
};

export const useDataPublicPaymentTypes = () => {
  return useCommonSWR<IApiPaymentType[]>({
    key: apiPublic.paymentTypes(),
    defaultData: [],
  });
};

export const useDataPublicPaymentStatuses = () => {
  return useCommonSWR<IApiPaymentStatus[]>({
    key: apiPublic.paymentStatuses(),
    defaultData: [],
  });
};
