import { hashObject } from "@/utils/data";
import { useCallback, useMemo } from "react";

const useThrottle = (delay: number = 1000, initKey?: any) => {
  const cont = useMemo(
    (): any => ({
      timer: undefined,
      key: hashObject(initKey),
    }),
    [initKey]
  );

  const throttle = useCallback(
    (cb: () => void, key: any) => {
      const newKey = hashObject(key);

      cont.key = newKey;
      clearTimeout(cont.timer);
      cont.timer = setTimeout(cb, delay);
    },
    [cont, delay]
  );

  return throttle;
};

export default useThrottle;
