const API_CMS = `/cms/`;
const API_PUBLIC = `/public/`;
const API_MARKETING = `${API_PUBLIC}marketing/`;
const API_STORES = `${API_PUBLIC}stores/`;
const API_CATEGORIES = `${API_PUBLIC}categories/`;
const API_PRODUCTS = `${API_PUBLIC}products/`;
const API_PROPERTIES = `${API_PUBLIC}properties/`;
const API_PROPERTIES_VALUES = `${API_PUBLIC}property-values/`;
const API_VARIANTS = `${API_PUBLIC}variants/`;
const API_COUNTRIES = `${API_PUBLIC}countries/`;
const API_MESSENGERS = `${API_PUBLIC}messengers/`;
const API_NEWSLETTER = `${API_PUBLIC}newsletters/`;

const API_MAP = `/map/`;

const API_NEXT = `/`;
const MOCK_API = `${API_NEXT}mock/`;

const apiPublic = {
  assets: () => `/assets/`,
  currencies: () => `${API_PUBLIC}currencies/`,
  languages: () => `${API_PUBLIC}languages/`,
  storesTags: () => `${API_PUBLIC}store_tags/`,
  storeTag: (tagId: any) => `${API_PUBLIC}store_tags/${tagId}/`,
  socialNetworks: () => `${API_PUBLIC}social_networks/`,
  brands: () => `${API_PUBLIC}brands/`,
  brandsByIds: (locale: string, ids?: any[]) => `${API_PUBLIC}brands/?lang=${locale}&id__in=${ids?.join(",")}`,
  brand: (brandId: any) => `${API_PUBLIC}brands/${brandId}/`,

  countries: () => `${API_COUNTRIES}`,
  countryProvinces: (countryCode: string) => `${API_COUNTRIES}${countryCode}/provinces/`,
  countryCities: (countryCode: string) => `${API_COUNTRIES}${countryCode}/cities/`,

  categoryBySlug: (slug: any, lang?: string) => `${API_CATEGORIES}categories-by-slug/${slug}/${lang ? `?lang=${lang}` : ""}`,
  breadcrumbs: (id: any, lang?: string) => `${API_CATEGORIES}${id}/breadcrumbs/${lang ? `?lang=${lang}` : ""}`,

  products: (locale: string, filterStr?: string) => `${API_PRODUCTS}?lang=${locale}${filterStr ? `&${filterStr}`: ""}`,
  productsMoreToLove: (locale: string, filterStr?: string) => `${API_MARKETING}more_to_love/?lang=${locale}&in_stock=1${filterStr ? `&${filterStr}`: ""}`,
  productsRelatedObjects: (locale: string) =>
    `${API_PRODUCTS}related-object-representation/${locale ? `?lang=${locale}` : ""}`,
  aggregatedProductsList: (locale: string, filterStr?: string) => `${API_PRODUCTS}?lang=${locale}${filterStr ? `&${filterStr}`: ""}`,
  sitemapProductsList: (filterStr: string) => `${API_PRODUCTS}?${filterStr}`,
  getSitemaps: () => `${API_CMS}sitemaps/`,
  // TODO not implemented
  featuredProducts: (locale: string, filterStr?: string, pageSize = 36) => `${API_PRODUCTS}?lang=${locale}&in_stock=1&page_size=${pageSize}&ordering=recommended${filterStr ? `&${filterStr}`: ""}`,
  salesProducts: (locale: string, filterStr?: string, pageSize = 36) => `${API_PRODUCTS}?lang=${locale}&in_stock=1&page_size=${pageSize}&sale=1${filterStr ? `&${filterStr}`: ""}`,
  product: (locale: string, slug: string) => `${API_PRODUCTS}${slug}/?lang=${locale}`,
  productProperties: (locale: string, productId: string) => `${API_PRODUCTS}${productId}/product-properties/?lang=${locale}`,
  propertyValuesByIds: (locale: string, ids?: any[]) => `${API_PUBLIC}property-values/?lang=${locale}&id__in=${ids?.join(",")}`,
  productVariants: (locale: string, productSlug: string) => `${API_PRODUCTS}${productSlug}/variants/?lang=${locale}&is_active=1`,
  productVariant: (locale: string, productSlug: string, variantId: any) => `${API_PRODUCTS}${productSlug}/variant/${variantId}/?lang=${locale}`,
  productVariantProperties: (locale: string, variantId: string) => `${API_VARIANTS}${variantId}/variant-properties/?lang=${locale}`,
  productReviews: (productSlug: string) => `${API_PRODUCTS}${productSlug}/reviews/`,
  productReviewsAverageValues: (productSlug: string) => `${API_PRODUCTS}${productSlug}/reviews-average-values/`,
  relatedProducts: (slug: string, locale: string, pageSize = 30) => `${API_PRODUCTS}${slug}/related_products/?lang=${locale}&page_size=${pageSize}`,
  property: (locale: string, propertyId: any) => `${API_PROPERTIES}${propertyId}/?lang=${locale}`,
  propertyValue: (locale: string, propertyValueId: any) => `${API_PROPERTIES_VALUES}${propertyValueId}/?lang=${locale}`,
  properties: (locale: string, filterStr?: string) => `${API_PROPERTIES}?lang=${locale}${filterStr ? `&${filterStr}` : ""}`,
  propertiesOfCategory: (locale: string, categoryId: any, filterStr?: string) => `${API_PROPERTIES}category/${categoryId}/?lang=${locale}${filterStr ? `&${filterStr}` : ""}`,

  storesDeliveryOptions: () => `${API_STORES}delivery/options/`,
  stores: (locale: string, filterStr?: string) => `${API_STORES}?lang=${locale}${filterStr ? `&${filterStr}` : ""}`,
  storesByIds: (locale: string, ids?: any[]) => `${API_STORES}?lang=${locale}&id__in=${ids?.join(",")}`,
  store: (slug: any) => `${API_STORES}${slug}/`,
  feturedStores: () => `${API_STORES}fetured_stores/`,
  newStores: () => `${API_STORES}new_stores/`,
  storeCategories: (storeSlug: any, lang?: string) =>
    `${API_STORES}${storeSlug}/categories/${lang ? `?lang=${lang}` : ""}`,

  filterPriceRange: () => `${API_PRODUCTS}get_price_range/`,
  mapAddresses: () => `${API_MAP}addresses/`,

  messengers: () => `${API_MESSENGERS}`,
  messenger: (code: string) => `${API_MESSENGERS}/${code}/`,

  banners: () => `${API_CMS}banners/`,
  staticPage: (locale: string, namePage: string) => `${API_CMS}pages/${namePage}/?lang=${locale}`,
  staticPages: () => `${API_CMS}pages/`,

  support: () => `/support/`,
  subscribe: () => `${API_NEWSLETTER}subscribe/`,
  confirmSubscription: () => `${API_NEWSLETTER}confirm/`,
  confirmUnsubscription: () => `${API_NEWSLETTER}unsubscribe/`,

  orderPaymentTypes: () => `${API_PUBLIC}order-payment-types/`,
  paymentTypes: () => `${API_PUBLIC}payment-types/`,
  paymentStatuses: () => `${API_PUBLIC}payment-statuses/`,

  // from next api
  sales: () => `${MOCK_API}sales/`,
};

export const nextApiPublic = {
  dictionary: (lang: string, target?: string) =>
    `${API_NEXT}translations/?lang=${lang}${target ? `&target=${target}` : ""}`,
  categories: (lang?: string) => `${API_NEXT}categories/${lang ? `?lang=${lang}` : ""}`,
  topCategories: (lang?: string) => `${API_NEXT}categories/?parent=null${lang ? `&lang=${lang}` : ""}`,
  categoriesWithParent: (parent: number, lang: string, onlyVirtual?: boolean) =>
    `${API_NEXT}categories/?parent=${parent}${lang ? `&lang=${lang}` : ""}${onlyVirtual ? "&only_virtual=1" : ""}`,
  categoriesByIds: (ids: number[], lang?: string) =>
    `${API_NEXT}categories/?ids=${ids.join(",")}${lang ? `&lang=${lang}` : ""}`,
  topCategoriesForIds: (ids: number[], lang?: string) =>
    `${API_NEXT}categories/?top_for_ids=${ids.join(",")}${lang ? `&lang=${lang}` : ""}`,
  categoryFilters: (lang: string) => `${API_NEXT}category-filters/${lang ? `?lang=${lang}` : ""}`,
  storeActiveCategories: (lang: string) => `${API_NEXT}active-categories/${lang ? `?lang=${lang}` : ""}`,
};

export default apiPublic;
